import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';

import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Steps} from "primereact/steps";
import {Message} from "primereact/message";
import {Dropdown} from "primereact/dropdown";
import UserRegistrationService from "../services/user/UserRegistrationService";
import UserRegistrationConfirmService from "../services/user/UserRegistrationConfirmService";
import CompanytypeListService from "../services/companytype/CompanytypeListService";
import UserRegistrationFinishService from "../services/user/UserRegistrationFinishService";
import NetworkListService from "../services/network/NetworkListService";
import ModulesListService from "../services/modules/ModulesListService";
import {InputSwitch} from "primereact/inputswitch";

const PasswordSetPage = () => {

  const [s1FormData, setS1FormData] = useState({
    terms: false
  });
  const [s2FormData, setS2FormData] = useState({});
  const [s3FormData, setS3FormData] = useState({
    companyType: 'organisation',
    addressCountryCode: 'HU',
    currency: 'HUF',
  });
  const [activationHash, setActivationHash] = useState('');
  const [loading, setLoading] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [modules, setModules] = useState([]);

  const s1Validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'A név megadása kötelező!';
    }
    if (!data.identifier) {
      errors.identifier = 'Az email mező nem lehet üres.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.identifier)) {
      errors.identifier = 'Mindenképpen email formátumra van szükség! Pl. example@email.com';
    }
    if (!data.password) {
      errors.password = 'A jelszó nem lehet üres';
    }
    if (data.terms !== true) {
      errors.terms = 'A feltételek és irányelvek elfogadása kötelező!';
    }
    return errors;
  };
  const onS1Submit = (data, form) => {
    setLoading(true)
    setS1FormData(data);

    let _data = {
      ...data,
      modules: modules
    }

    UserRegistrationService(_data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres regisztráció!', 'Kérjük kövesd az emailben küldött utasításokat a folytatáshoz!', 20000);
        setLoading(false)
        setActiveIndex(1)
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    form.restart();
  };

  const s2Validate = (data) => {
    let errors = {};
    if (!data.identifier) {
      errors.identifier = 'Az email cím nem lehet üres';
    }
    if (!data.token) {
      errors.token = 'A megerősítő kód cím nem lehet üres';
    }
    return errors;
  };
  const onS2Submit = (data, form) => {
    setLoading(true)
    setS2FormData(data);
    UserRegistrationConfirmService(data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres megerősítés!', 'Már csak új céged adatait kell megadnod!', 20000);
        setActivationHash(response.data.activationHash)
        setLoading(false)
        setActiveIndex(2)
      })
      .catch(error => {
        setLoading(false)
        if (error.toString().includes('404')) {
          window.App.toastShow('warn', 'Helytelen kód!', 'Használd az emailben küldött kódot vagy linket, vagy ha elakadtál kezd újra a regisztrációt.', 20000);
        } else {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        }
      })
    form.restart();
  };

  const s3Validate = (data) => {
    let errors = {};
    if (!data.companyName) {
      errors.companyName = 'A vállalkozás neve kötelező!';
    }
    if (!data.companyType) {
      errors.companyType = 'A cégforma kötelező!';
    }
    if (!data.vatNumber) {
      errors.vatNumber = 'Az adószám kötelező!';
    }
    if (!data.addressCountryCode) {
      errors.addressCountryCode = 'Kötelező mező!';
    }
    if (!data.addressPostalCode) {
      errors.addressPostalCode = 'Kötelező mező!';
    }
    if (!data.addressCity) {
      errors.addressCity = 'Kötelező mező!';
    }
    if (!data.addressDetail) {
      errors.addressDetail = 'Kötelező mező!';
    }

    return errors;
  };
  const onS3Submit = (data, form) => {
    if (activeIndex === 2) {
      setActiveIndex(3)
    } else {
      setLoading(true)
      setS3FormData(data);

      UserRegistrationFinishService(s3FormData, activationHash)
        .then(response => {
          window.App.toastShow('success', 'Sikeres regisztráció!', 'Üdvözöl a Trackie.io!');
          setActivationHash('')
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("userToken", response.data.token);
          localStorage.setItem("userName", data.identifier);
          localStorage.setItem("userRealName", response.data.name);
          localStorage.setItem("roles", JSON.stringify(response.data.roles).toLowerCase())
          NetworkListService()
            .then(response => {
              if (response.data && response.data[0]) {
                window.App.setState({
                  networks: response.data
                })
                if (!localStorage.getItem('networkId') && !localStorage.getItem('networkName')) {
                  localStorage.setItem('networkId', response.data[0].id)
                  localStorage.setItem('networkName', response.data[0].name)
                }
              }
              ModulesListService()
                .then(response => {
                  Object.entries(response.data).map(function (item, index) {
                    localStorage.setItem('module_' + item[1].code, item[1].name);
                    if (index == response.data.length - 1) {
                      document.getElementById('body').classList.remove('outside')
                      window.App.toastShow('success', 'Sikeres belépés!');
                      //setTimeout(function () {
                      window.location.replace('/#/');
                      window.App.forceUpdate()
                      window.App.setState({loggedIn: true})
                      //window.location.reload() // TODO
                      //}, 2000)
                    }
                  })
                })
                .catch(error => {
                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                  setLoading(false)
                })
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              setLoading(false)
            })
        })
        .catch(error => {
          setLoading(false)
          if (
            JSON.stringify(error.response.data).includes('companyName')
            || JSON.stringify(error.response.data).includes('companyType')
            || JSON.stringify(error.response.data).includes('vatNumber')
            || JSON.stringify(error.response.data).includes('registrationNumber')
            || JSON.stringify(error.response.data).includes('addressCountryCode')
            || JSON.stringify(error.response.data).includes('addressPostalCode')
            || JSON.stringify(error.response.data).includes('addressCity')
            || JSON.stringify(error.response.data).includes('addressDetail')
          ) {
            setActiveIndex(2)
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          } else {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          }
        })
      form.restart();
    }
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('App-Network');
    localStorage.removeItem('networkName');
    localStorage.removeItem('roles');
    localStorage.removeItem('helpWelcome');
    Object.keys(localStorage)
      .filter(key => key.startsWith("module_"))
      .forEach(key => localStorage
        .removeItem((key))
      )
    window.App.setState({
      subscriptionMessages: []
    })
    Object.keys(localStorage)
      .filter(key => key.startsWith("subscriptionMessage_"))
      .forEach(key => localStorage
        .removeItem((key))
      )
    window.App.forceUpdate()

    document.title = 'Regisztráció' + ' - ' + process.env.REACT_APP_TITLE;
    document.getElementById('body').classList.add('outside')

    if (window.location.href.includes('?')) {
      let url = JSON.stringify(window.location.href.split('?')[1])
      let _url = url.replaceAll('%22', '"')
      _url = _url.replace('"[', "[")
      _url = _url.replace(']"', "]")
      setModules(JSON.parse(_url))
    } else {
      setInvalidData(true)
      window.App.toastShow('warn', 'Hiányzó adatok!', 'A regisztrációs folyamat megkezdése helytelen!', 20000);
    }

    // let _s2FormData = {}
    // if (window.App.urlVars().email) {
    //   _s2FormData = {
    //     ..._s2FormData,
    //     identifier: window.App.urlVars().email
    //   }
    // }
    // if (window.App.urlVars().token) {
    //   _s2FormData = {
    //     ..._s2FormData,
    //     token: window.App.urlVars().token
    //   }
    // }
    // setS2FormData(_s2FormData)

    if (window.App.urlVars().hash) {
      window.App.toastShow('success', 'Sikeres megerősítés!', 'Már csak új céged adatait kell megadnod!', 20000);
      setActiveIndex(2)
      setActivationHash(window.App.urlVars().hash)
    }

  }, []);

  useEffect(() => {
    if (activeIndex === 1 && s1FormData && s1FormData.identifier) {
      setS2FormData({
        ...s2FormData,
        identifier: s1FormData.identifier
      })
    }
    if (activeIndex === 2) {
      CompanytypeListService()
        .then(response => {
          setCompanyTypes(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }

  }, [activeIndex]);

  return (
    <div className="LoginPage p-component p-p-3">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE).default} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-12 p-xl-12"}>
            <h3>
              <i className={"pi pi-user"}></i> Regisztráció
            </h3>
          </div>
        </div>
      } className={""}>

        <Steps model={[
          {
            label: 'Felhasználó',
          },
          {
            label: 'Megerősítés',
          },
          {
            label: 'Cég adatok',
          },
          {
            label: 'Opcionális beállítások',
          }
        ]} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true}/>

        {activeIndex === 0 &&
        <Form onSubmit={onS1Submit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={s1FormData}
              validate={s1Validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12"}>
                <Field name="name" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="name"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Név *</label>
                    <span className="p-input-icon-right">
                    <i className="pi pi-user"/>
                    <InputText id="name"
                               {...input}
                               disabled={invalidData}
                               value={s1FormData.name}
                               onChange={(e) => {
                                 setS1FormData({
                                   ...s1FormData,
                                   name: e.target.value
                                 })
                               }}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Field name="identifier" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="identifier"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Email *</label>
                    <span className="p-input-icon-right">
                    <i className="pi pi-envelope"/>
                    <InputText id="identifier"
                               {...input}
                               disabled={invalidData}
                               value={s1FormData.identifier}
                               onChange={(e) => {
                                 setS1FormData({
                                   ...s1FormData,
                                   identifier: e.target.value
                                 })
                               }}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Field name="password" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="password"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Jelszó *</label>
                    <span className="p-input-icon-right">
                    <Password id="password"
                              {...input}
                              disabled={invalidData}
                              toggleMask
                              feedback={false}
                              value={s1FormData.password}
                              onChange={(e) => {
                                setS1FormData({
                                  ...s1FormData,
                                  password: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-col-align-center"}>
                <Field name="terms" render={({input, meta}) => (
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col"}>
                        <div className="p-field p-fluid">
                          <InputSwitch
                            className={"p-mt-1"}
                            checked={s1FormData.terms}
                            value={s1FormData.terms}
                            onChange={e => setS1FormData({...s1FormData, terms: e.target.value})}/>
                        </div>
                      </div>
                      <div className={"p-col-10 p-col-align-center"}>
                        <small className={"p-d-block"}>A feltételeket és irányelveket megismertem és elfogadom!</small>
                        {getFormErrorMessage(meta)}
                      </div>
                    </div>
                  </>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/login"}>Bejelentkezés</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <>
                  <br/>
                  <a href={process.env.REACT_APP_REGISTRATION_LANDING_URL}>Modulok kiválasztása</a>
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="submit" label="Regisztráció" className="p-button-success" icon={"pi pi-check"}
                          loading={loading} disabled={invalidData}/>
              </div>
            </div>
          </form>
        )}/>
        }

        {activeIndex === 1 &&
        <Form onSubmit={onS2Submit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={s2FormData}
              validate={s2Validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12"}>
                <Message severity="info"
                         text="Kérjük add meg az emailben kézbesített megerősítő kódot, vagy klikkelj az emailben található megerősítő linkre!"
                         className={"w-100"}></Message>
              </div>
              <div className={"p-col-12"}>
                <Field name="identifier" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="identifier"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Email cím *</label>
                    <span className="p-input-icon-right">
                    <InputText id="identifier"
                               {...input}
                               value={s2FormData.identifier}
                               onChange={(e) => {
                                 setS2FormData({
                                   ...s2FormData,
                                   identifier: e.target.value
                                 })
                               }}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Field name="token" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="token"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Megerősítő kód *</label>
                    <span className="p-input-icon-right">
                    <InputText id="token"
                               {...input}
                               value={s2FormData.token}
                               onChange={(e) => {
                                 setS2FormData({
                                   ...s2FormData,
                                   token: e.target.value
                                 })
                               }}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/login"}>Bejelentkezés</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <>
                  <br/>
                  <Link to={"/registration"} onClick={() => {
                    setActiveIndex(0)
                  }}>Regisztráció</Link>
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Megerőstés" className="p-button-success" icon={"pi pi-check"}
                        loading={loading} disabled={submitting}/>
              </div>
            </div>
          </form>
        )}/>
        }

        {(activeIndex === 2 || activeIndex === 3) &&
        <Form onSubmit={onS3Submit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={s3FormData}
              validate={s3Validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            {activeIndex === 2 &&
            <>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-8"}>
                  <Field name="companyName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="companyName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Vállalkozás neve *</label>
                      <span className="p-input-icon-right">
                      <InputText id="companyName"
                                 {...input}
                                 value={s3FormData.companyName}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     companyName: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4"}>
                  <Field name="companyType" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="code"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Cégforma</label>
                      <span className="p-input-icon-right">
                      <Dropdown options={companyTypes}
                                {...input}
                                optionLabel="name"
                                optionValue="id"
                                id={"companyType"}
                                emptyMessage={"Még nincs cégforma..."}
                                onChange={(e) => {
                                  setS3FormData({
                                    ...s3FormData,
                                    companyType: e.value
                                  })
                                }}
                                value={s3FormData.companyType}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-0"}>
                <div className={"p-col"}>
                  <Field name="vatNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="vatNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Adószám *</label>
                      <span className="p-input-icon-right">
                      <InputText id="vatNumber"
                                 {...input}
                                 value={s3FormData.vatNumber}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     vatNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {s3FormData && s3FormData.companyType && s3FormData.companyType === 'organisation' &&
                <div className={"p-col"}>
                  <Field name="registrationNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="registrationNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Cégjegyzékszám</label>
                      <span className="p-input-icon-right">
                      <InputText id="registrationNumber"
                                 {...input}
                                 value={s3FormData.registrationNumber}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     registrationNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
                {s3FormData && s3FormData.companyType && s3FormData.companyType === 'self employed' &&
                <div className={"p-col"}>
                  <Field name="registrationNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="registrationNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Nyilvántartási szám</label>
                      <span className="p-input-icon-right">
                      <InputText id="registrationNumber"
                                 {...input}
                                 value={s3FormData.registrationNumber}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     registrationNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
              </div>
              <div className={"p-grid p-mt-0"}>
                <div className={"p-col-12 p-lg-4"}>
                  <Field name="addressCountryCode" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="addressCountryCode"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Ország</label>
                      <span className="p-input-icon-right">
                      <Dropdown options={[
                        {
                          name: 'Magyarország',
                          value: 'HU'
                        }
                      ]}
                                {...input}
                                optionLabel="name"
                                optionValue="value"
                                id={"addressCountryCode"}
                                emptyMessage={"Még nincs cégforma..."}
                                onChange={(e) => {
                                  setS3FormData({
                                    ...s3FormData,
                                    addressCountryCode: e.value
                                  })
                                }}
                                value={s3FormData.addressCountryCode}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4"}>
                  <Field name="addressPostalCode" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="addressPostalCode"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>IRSZ *</label>
                      <span className="p-input-icon-right">
                      <InputText id="addressPostalCode"
                                 {...input}
                                 value={s3FormData.addressPostalCode}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     addressPostalCode: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4"}>
                  <Field name="addressCity" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="addressCity"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Város *</label>
                      <span className="p-input-icon-right">
                      <InputText id="addressCity"
                                 {...input}
                                 value={s3FormData.addressCity}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     addressCity: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12"}>
                  <Field name="addressDetail" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="addressDetail"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Cím *</label>
                      <span className="p-input-icon-right">
                      <InputText id="addressDetail"
                                 {...input}
                                 value={s3FormData.addressDetail}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     addressDetail: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </>
            }
            {activeIndex === 3 &&
            <>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col"}>
                  <Field name="communityVatNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="communityVatNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Közösségi adószám</label>
                      <span className="p-input-icon-right">
                      <InputText id="communityVatNumber"
                                 {...input}
                                 value={s3FormData.communityVatNumber}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     communityVatNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-8"}>
                  <Field name="receiptOtherData" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="receiptOtherData"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Egyéb számlán feltűntetedő
                        adat</label>
                      <span className="p-input-icon-right">
                      <InputText id="receiptOtherData"
                                 {...input}
                                 value={s3FormData.receiptOtherData}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     receiptOtherData: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col"}>
                  <Field name="taxFree" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="taxFree"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Alanyi adómentesség</label>
                      <span className="p-input-icon-right">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={s3FormData.taxFree}
                      value={s3FormData.taxFree}
                      onChange={e => setS3FormData({...s3FormData, taxFree: e.target.value})}/>
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <h4>
                <i className={"pi pi-sync"}></i> NAV Interfész
              </h4>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="navUserName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="navUserName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>NAV felhasználónév</label>
                      <span className="p-input-icon-right">
                      <InputText id="navUserName" {...input}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     navUserName: e.target.value
                                   })
                                 }}
                                 value={s3FormData.navUserName}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="navUserPass" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="navUserPass"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>NAV jelszó</label>
                      <span className="p-input-icon-right">
                      <InputText id="navUserPass" {...input}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     navUserPass: e.target.value
                                   })
                                 }}
                                 value={s3FormData.navUserPass}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="navXMLSignKey" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="navXMLSignKey"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>XML aláírókulcs</label>
                      <span className="p-input-icon-right">
                      <InputText id="navXMLSignKey" {...input}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     navXMLSignKey: e.target.value
                                   })
                                 }}
                                 value={s3FormData.navXMLSignKey}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="navXMLExchangeKey" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="navXMLExchangeKey"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>XML cserekulcs</label>
                      <span className="p-input-icon-right">
                      <InputText id="navXMLExchangeKey" {...input}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     navXMLExchangeKey: e.target.value
                                   })
                                 }}
                                 value={s3FormData.navXMLExchangeKey}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="isNavTest" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="isNavTest"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Teszt környezet</label>
                      <span className="p-input-icon-right">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={s3FormData.isNavTest}
                      value={s3FormData.isNavTest}
                      onChange={e => setS3FormData({...s3FormData, isNavTest: e.target.value})}/>
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <h4>
                <i className={"pi pi-envelope"}></i> Kapcsolat
              </h4>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-6"}>
                  <Field name="companyEmail" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="companyEmail"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Szervezet email címe</label>
                      <InputText id="companyEmail"
                                 {...input}
                                 value={s3FormData.companyEmail}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     companyEmail: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-6"}>
                  <Field name="companyPhone" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="companyPhone"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Szervezet telefonszáma</label>
                      <InputText id="companyPhone"
                                 {...input}
                                 value={s3FormData.companyPhone}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     companyPhone: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <h4>
                <i className={"pi pi-wallet"}></i> Banki adatok
              </h4>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-6"}>
                  <Field name="bankAccountName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="bankAccountName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Bank neve</label>
                      <InputText id="bankAccountName"
                                 {...input}
                                 value={s3FormData.bankAccountName}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     bankAccountName: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-6"}>
                  <Field name="bankAccountNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="bankAccountNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Bankszámla szám</label>
                      <InputText id="bankAccountNumber"
                                 {...input}
                                 value={s3FormData.bankAccountNumber}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     bankAccountNumber: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-6"}>
                  <Field name="bankAccountSwift" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="bankAccountSwift"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Swift</label>
                      <InputText id="bankAccountSwift"
                                 {...input}
                                 value={s3FormData.bankAccountSwift}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     bankAccountSwift: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-6"}>
                  <Field name="bankAccountIBAN" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="bankAccountIBAN"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>IBAN</label>
                      <InputText id="bankAccountIBAN"
                                 {...input}
                                 value={s3FormData.bankAccountIBAN}
                                 onChange={(e) => {
                                   setS3FormData({
                                     ...s3FormData,
                                     bankAccountIBAN: e.target.value
                                   })
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4"}>
                  <Field name="currency" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="currency"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Alap pénznem</label>
                      <Dropdown options={[
                        {
                          name: 'HUF',
                          value: 'HUF'
                        }
                      ]}
                                {...input}
                                optionLabel="name"
                                optionValue="value"
                                id={"currency"}
                                emptyMessage={"Még nincs pénznem..."}
                                onChange={(e) => {
                                  setS3FormData({
                                    ...s3FormData,
                                    currency: e.value
                                  })
                                }}
                                value={s3FormData.currency}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>

            </>
            }
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-4 p-xl-4"}>
                <Link to={"/login"}>Bejelentkezés</Link>
              </div>
              <div className={"p-col-12 p-lg-8 p-xl-8 p-text-right"}>
                {activeIndex === 2 ? (
                  <Button type="submit" label="Egyéb adatok megadása" className="p-button-success" icon={"pi pi-check"}
                          loading={loading} disabled={submitting}/>
                ) : (
                  <>
                    <a className="p-button p-button-secondary p-mr-2"
                       onClick={() => {
                         setActiveIndex(2)
                       }}
                    ><i className={"pi pi-arrow-left"}></i></a>
                    <Button type="submit" label="Regisztráció befejezése" className="p-button-success"
                            icon={"pi pi-check"}
                            loading={loading} disabled={submitting}/>
                  </>
                )}
              </div>
            </div>
          </form>
        )}/>
        }
        <div className={"p-text-center p-mt-5"}>
          <span className={"p-text-secondary"}
                dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
        </div>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(PasswordSetPage);
