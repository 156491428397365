import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import Kalend, {CalendarView} from 'kalend'
import 'kalend/dist/styles/index.css';
import {Panel} from "primereact/panel";
import hu from "../../translations/hu/kalend.json"
import ScheduleitemCalendarService from "../../services/schedulegroup/ScheduleitemCalendarService";

const SchedulegroupShowComponent = (props) => {

  const [events, setEvents] = useState([]);

  useEffect(() => {
      ScheduleitemCalendarService(
        JSON.stringify({
          filters: {
            scheduleGroup: {value: props.initialValues.id}
          }
        })
      )
        .then(response => {
          let _events = []
          response.data.items.map(function (item, index) {
            if (item) {
              _events.push({
                id: item.id,
                startAt: item.dateFrom,
                endAt: item.dateUntil,
                summary: item.employeeName + ' / ' + item.machineName,
                // color: 'blue',
                // calendarID: 'work'
              })
            }
          })
          setEvents(_events)
          window.App.setState({rerenderScheduleShow: false})
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, [window.App.state.rerenderScheduleShow === true]
  )

  // useEffect(() => {
  //   ProjectactionListService()
  //     .then(response => {
  //       let _events = []
  //       response.data.items.map(function (action, index) {
  //         if (action && action.scheduledFrom) {
  //           _events.push({
  //             id: action.id,
  //             startAt: action.scheduledFrom,
  //             endAt: action.scheduledUntil,
  //             summary: action.projectName + ' / ' + action.employeeName + ' / ' + action.machineName,
  //             // color: 'blue',
  //             // calendarID: 'work'
  //           })
  //         }
  //       })
  //       setEvents(_events)
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //     })
  // }, [])

  return (
    <>
      <Panel className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>

        </div>
        <div style={{height: '665px'}}>
          <Kalend
            customLanguage={hu}
            onEventClick={(e) => {
              window.App.setState({
                sidebarManufacturingcalendarUpdate: true,
                props: e
              })
            }}
            // onNewEventClick={(e) => {
            //   console.log(e)
            // }}
            // onPageChange={(e) => {
            //   console.log(e)
            // }}
            events={events}
            initialDate={new Date().toISOString()}
            hourHeight={20}
            initialView={CalendarView.WEEK}
            disabledViews={[CalendarView.THREE_DAYS]}
            timeFormat={'24'}
            weekDayStart={'Monday'}
            language={'en'}
          />
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(SchedulegroupShowComponent);
