import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";

import ProductionitemFormService from "../../services/productionitem/ProductionitemFormService";
import {AutoComplete} from "primereact/autocomplete";
import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import TechnologyListService from "../../services/technology/TechnologyListService";
import MachineListService from "../../services/machine/MachineListService";
import EmployeeListService from "../../services/employee/EmployeeListService";
import ProductionitemShowService from "../../services/productionitem/ProductionitemShowService";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {addLocale} from "primereact/api";
import {InputMask} from "primereact/inputmask";
import UnitListService from "../../services/unit/UnitListService";

const ProductionitemFormComponent = (props) => {

  const [formData, setFormData] = useState({
    projectItem: {
      id: props.projectitemId
    }
  });
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((window.App.state.productionitemFormMethod && window.App.state.productionitemFormMethod === 'update') || (props && props.initialValues && props.initialValues.id)) {
      ProductionitemFormService(data, 'put', props.initialValues.id)
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProductionitemList: true,
            rerenderScheduleitemscalendarCalendar: true,
            sidebarProductionitemUpdate: false,
            dialogProductionitem: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProductionitemFormService(data, 'post', props.projectId)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProductionitemList: true,
            sidebarProductionitemUpdate: false,
            dialogProductionitem: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // technology autocomplete
  const [technologies, setTechnologies] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState();

  const searchTechnology = (event) => {
    setSelectedTechnology(event.query.replace('Nincs találat', '').replace(': ', ''))
    TechnologyListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setTechnologies(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setTechnologies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedTechnology(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // machine autocomplete
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();

  const searchMachine = (event) => {
    setSelectedMachine(event.query.replace('Nincs találat', '').replace(': ', ''))
    MachineListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setMachines(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setMachines(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedMachine(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // employee autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();

  const searchEmployee = (event) => {
    setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setEmployees(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setEmployees(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEmployee(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    UnitListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      ProductionitemShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setSelectedMachine(response.data.machineName)
          setSelectedEmployee(response.data.employeeName)
          setSelectedTechnology(response.data.technologyName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    } else if (window.App.state.props && window.App.state.props.id) {
      ProductionitemShowService(window.App.state.props.id)
        .then(response => {
          setFormData(response.data)
          setSelectedMachine(response.data.machineName)
          setSelectedEmployee(response.data.employeeName)
          setSelectedTechnology(response.data.technologyName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="technology" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="technology"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Technológia</label>
                  <span className="p-input-icon-right">
                            <AutoComplete value={selectedTechnology}
                                          id={"technology"}
                                          suggestions={technologies}
                                          completeMethod={searchTechnology}
                                          field={"name"}
                                          delay="500"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              technology: {id: e.value.id}
                                            })
                                            setSelectedTechnology(e.value.name)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedTechnology('')
                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="machine" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="machine"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Gép</label>
                  <span className="p-input-icon-right">
                            <AutoComplete value={selectedMachine}
                                          id={"machine"}
                                          suggestions={machines}
                                          completeMethod={searchMachine}
                                          field={"name"}
                                          delay="500"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              machine: {id: e.value.id}
                                            })
                                            setSelectedMachine(e.value.name)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedMachine('')
                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="employee" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="employee"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className="p-input-icon-right">
                            <AutoComplete value={selectedEmployee}
                                          id={"employee"}
                                          suggestions={employees}
                                          completeMethod={searchEmployee}
                                          field={"name"}
                                          delay="500"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              employee: {id: e.value.id}
                                            })
                                            setSelectedEmployee(e.value.name)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedEmployee('')
                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-8"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <div className="p-inputgroup">
                    <InputText id="name" {...input}
                               onChange={(e) => {
                                 setFormData({
                                   ...formData,
                                   name: e.target.value
                                 })
                               }}
                               value={formData.name}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    <Button type="button" icon="pi pi-ellipsis-h" className="p-button-default"
                            onClick={() => {
                              let separator = ""
                              let _selectedTechnology = ""
                              let _selectedMachine = ""
                              if(selectedMachine && selectedMachine.toString().length > 1) {
                                _selectedMachine = selectedMachine
                              }
                              if(selectedTechnology && selectedTechnology.toString().length > 1) {
                                _selectedTechnology = selectedTechnology
                              }
                              if(selectedTechnology && selectedMachine) {
                                separator = ", "
                              }
                              setFormData({
                                ...formData,
                                name: _selectedTechnology + separator + _selectedMachine
                              })
                            }}
                    />
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="externalReference" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="externalReference"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Külső azonosító</label>
                  <span className="p-input-icon-right">
                      <InputText id="externalReference" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     externalReference: e.target.value
                                   })
                                 }}
                                 value={formData.externalReference}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {(formData.status !== 'done' || formData.status !== 'deleted') &&
            <>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="estimatedTime" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="estimatedTime"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Becsült időráfordítás</label>
                    <span className="p-input-icon-right">
                              <InputNumber id="estimatedTime" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               estimatedTime: e.value
                                             })
                                           }}
                                           value={formData.estimatedTime}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </>
            }
            <div className={"p-col-12 p-lg-4"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                  <span className="p-input-icon-right">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>
                  <span className="p-input-icon-right">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {/*<Button type="button" label="Megtekintés" icon={"pi pi-eye"}*/}
              {/*        onClick={() => {*/}
              {/*          window.App.setState({*/}
              {/*            sidebarProductionitemShow: true,*/}
              {/*            sidebarProductionitemUpdate: false,*/}
              {/*            props: formData,*/}
              {/*          })*/}
              {/*        }}*/}
              {/*        className="p-button"/>*/}
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProductionitemFormComponent);
