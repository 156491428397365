import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import download from "js-file-download";
import moment from "moment";
import currencyFormatter from "currency-formatter";

import ProjectproductListService from "../../services/project/ProjectproductListService";
import ProjectproductDeleteService from "../../services/project/ProjectproductDeleteService";

const ProjectproductListComponent = (props) => {

  const [projectproducts, setProjectproducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
    filters: {
      projectItem: {value: props.projectitemId}
    }
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectproductList: false})
      ProjectproductListService(JSON.stringify(lazyParams), props.projectId)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProjectproducts(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteProjectproduct = (rowData) => {
    ProjectproductDeleteService(props.projectId, rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarProjectproductUpdate: true,
                    dialogProjectproduct: true,
                    projectproductFormMethod: 'update',
                    projectproductRowData: rowData
                  })
                }}/>
        <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                tooltip="Nem jelent meg"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    deleteProjectproduct(rowData)
                  }
                }}
        />
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProjectproductList === true])

  return (
    <DataTable
      emptyMessage="Nincs találat."
      value={projectproducts} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
      responsiveLayout="scroll"
      stripedRows
      dataKey="id"
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
      <Column field="quantity" header="Mennyiség" className={"p-text-right"} body={(rowData) => {
        return (
          <>
            {rowData.quantity}
          </>
        )
      }}></Column>
      <Column field="unitPrice" header="Nettó érték" className={"p-text-right p-text-nowrap"}
              body={(rowData) => {
                let currency
                currency = {
                  precision: 2,
                  symbol: '',
                  decimal: ',',
                  thousand: ' ',
                }
                return (
                  <>
                    {currencyFormatter.format(
                      rowData.unitPrice, {
                        precision: currency.precision,
                        symbol: currency.symbol,
                        decimal: currency.decimal,
                        thousand: currency.thousand,
                      }
                    )}
                  </>
                )
              }}></Column>
    </DataTable>
  )
}
export default withTranslation('common')(ProjectproductListComponent);
