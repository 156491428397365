import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import Kalend, {CalendarView} from 'kalend'
import 'kalend/dist/styles/index.css';
import HrcalendarListService from "../../services/hrcalendar/HrcalendarListService";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from "primereact/inputswitch";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {Panel} from "primereact/panel";
import {classNames} from "primereact/utils";
import hu from "../../translations/hu/kalend.json"

import EmployeeListService from "../../services/employee/EmployeeListService";
import CalendartypeListService from "../../services/calendartype/CalendartypeListService";


const HercalendarListComponent = (props) => {

  const [events, setEvents] = useState([]);
  const [filterEmployeeName, setFilterEmployeeName] = useState();
  const [employees, setEmployees] = useState([]);
  const [filterTypeName, setFilterTypeName] = useState();
  const [types, setTypes] = useState([]);


  const searchEmployee = (event) => {
    setFilterEmployeeName(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setEmployees(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setFilterEmployeeName(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const searchType = (event) => {
    setFilterTypeName(event.query.replace('Nincs találat', '').replace(': ', ''))
    CalendartypeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setTypes(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setFilterTypeName(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    HrcalendarListService(
      JSON.stringify({
        filters: {
          employeeName: {value: filterEmployeeName},
          typeName: {value: filterTypeName}
        }
      })
    )
      .then(response => {
        setEvents(response.data.items)
        window.App.setState({rerenderHrcalendarList: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.rerenderHrcalendarList === true])

  return (
    <>
      <Panel className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <div className="p-field p-fluid">
              <label>Munkavállaló</label>
              <span className="p-input-icon-right">
                <AutoComplete value={filterEmployeeName}
                              id={"employee"}
                              suggestions={employees}
                              // forceSelection
                              completeMethod={searchEmployee}
                              field={"name"}
                              delay="500"
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                window.App.setState({rerenderHrcalendarList: true})
                                setFilterEmployeeName(e.value.name)
                              }}
                              dropdown
                              onClear={(e) => {
                                setFilterEmployeeName('')
                              }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <div className="p-field p-fluid">
              <label>Típus</label>
              <span className="p-input-icon-right">
                <AutoComplete value={filterTypeName}
                              id={"types"}
                              suggestions={types}
                              // forceSelection
                              completeMethod={searchType}
                              field={"name"}
                              delay="500"
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                window.App.setState({rerenderHrcalendarList: true})
                                setFilterTypeName(e.value.name)
                              }}
                              dropdown
                              onClear={(e) => {
                                setFilterTypeName('')
                              }}
                />
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div style={{height: '665px'}}>
        <Kalend
          customLanguage={hu}
          onEventClick={(e) => {
            window.App.setState({
              sidebarHrcalendarUpdate: true,
              props: e
            })
          }}
          // onNewEventClick={(e) => {
          //   console.log(e)
          // }}
          events={events}
          initialDate={new Date().toISOString()}
          hourHeight={20}
          initialView={CalendarView.MONTH}
          disabledViews={[CalendarView.DAY, CalendarView.THREE_DAYS]}
          timeFormat={'24'}
          weekDayStart={'Monday'}
          language={'en'}
        />
      </div>
    </>
  )
}
export default withTranslation('common')(HercalendarListComponent);
