import axios from "axios";

export default function UserNetworkDeleteService(userId, networkId) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/user/" + userId + "/network/" + networkId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
