import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {BlockUI} from "primereact/blockui";

import GuestFormService from '../../services/guest/GuestFormService'
import IdentitydocumenttypeListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import CompanyListService from "../../services/company/CompanyListService";
import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import GuestShowService from "../../services/guest/GuestShowService";
import IdentifierByGuestListService from "../../services/identifier/IdentifierByGuestListService";
import IdentifierByEmployeeListService from "../../services/identifier/IdentifierByEmployeeListService";
import {AutoComplete} from "primereact/autocomplete";
import GuestEntryreasonService from "../../services/guest/GuestEntryreasonService";

const GuestFormComponent = (props) => {

  const [formData, setFormData] = useState({
    //entryLocation: {id: Number(localStorage.getItem('defaultProjectId'))},
    company: {id: Number(localStorage.getItem('defaultCompanyId'))},
  });
  const [loading, setLoading] = useState(false);
  const [identitydocumenttypes, setIdentitydocumenttypes] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [reasons, setReasons] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.firstName) {
    //   errors.firstName = 'Az név nem lehet üres';
    // }
    // if (!data.lastName) {
    //   errors.lastName = 'Az név nem lehet üres';
    // }
    // if (!data.email) {
    //   errors.email = 'Az email nem lehet üres';
    // }
    // // if (!data.entryLocation) {
    // //   errors.entryLocation = 'A projekt nem lehet üres';
    // // }
    // if (!data.company) {
    //   errors.company = 'A cég nem lehet üres';
    // }
    // if (!data.type) {
    //   errors.type = 'A típust meg kell adni';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if ((props && props.initialValues) || formData.id) {
      GuestFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderGuestList: true})
          if (data.closeForm === true) {
            window.App.setState({
              sidebarGuestUpdate: false,
              sidebarGuestCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      GuestFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData(response.data)
          window.App.setState({rerenderGuestList: true})
          setTimeout(function () {
            IdentifierByGuestListService('', response.data.id)
              .then(response => {
                setIdentifiers(response.data.items)
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }, 1000)
          if (data.closeForm === true) {
            window.App.setState({
              sidebarGuestCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // entryLocation autocomplete
  const [entryLocations, setEntryLocations] = useState([]);
  const [selectedEntryLocation, setSelectedEntryLocation] = useState(localStorage.getItem('defaultProjectName'));

  const searchEntryLocation = (event) => {
    setSelectedEntryLocation(event.query.replace('Nincs találat', '').replace(': ', ''))
    EntrylocationListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        setEntryLocations(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setEntryLocations(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEntryLocation(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    IdentitydocumenttypeListService()
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    GuestEntryreasonService()
      .then(response => {
        setReasons(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (formData.id) {
      IdentifierByGuestListService('', formData.id)
        .then(response => {
          setIdentifiers(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    if (props && props.initialValues) {
      IdentifierByGuestListService('', props.initialValues.id)
        .then(response => {
          setIdentifiers(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      GuestShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      setFormData(props.initialValues)
      setSelectedCompany(props.initialValues.companyName)
      setSelectedEntryLocation(props.initialValues.entryLocationName)
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="lastName" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="lastName"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vezetéknév</label>
                  <span className="p-input-icon-right">
                      <InputText id="lastName" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     lastName: e.target.value
                                   })
                                 }}
                                 value={formData.lastName}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="firstName" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="firstName"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Keresztnév</label>
                  <span className="p-input-icon-right">
                      <InputText id="firstName" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     firstName: e.target.value
                                   })
                                 }}
                                 value={formData.firstName}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="organisation" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="organisation"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Szervezet</label>
                  <span className="p-input-icon-right">
                      <InputText id="organisation" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     organisation: e.target.value
                                   })
                                 }}
                                 value={formData.organisation}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="email" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="email"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                  <span className="p-input-icon-right">
                      <InputText id="email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 value={formData.email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-8 p-lg-8"}>
              <Field name="note" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="note"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megjegyzés</label>
                  <span className="p-input-icon-right">
                      <InputText id="note" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     note: e.target.value
                                   })
                                 }}
                                 value={formData.note}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="type" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="type"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-input-icon-right">
                    {formData && formData.id ? (
                      <Dropdown options={[
                        {
                          name: 'Elektronikus meghívó',
                          value: 'invite'
                        },
                        {
                          name: 'Sorszámozott vendégkártya',
                          value: 'serial'
                        }
                      ]}
                                optionLabel="name"
                                optionValue="value"
                                id={"type"}
                                emptyMessage={"Még nincs típus..."}
                                value={formData.type}
                                disabled
                      />
                    ) : (
                      <Dropdown options={[
                        {
                          name: 'Elektronikus meghívó',
                          value: 'invite'
                        },
                        {
                          name: 'Sorszámozott vendégkártya',
                          value: 'serial'
                        }
                      ]}
                                optionLabel="name"
                                optionValue="value"
                                id={"type"}
                                emptyMessage={"Még nincs típus..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    type: e.value
                                  })
                                }}
                                value={formData.type}
                                showClear
                      />
                    )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="entryReason" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="entryReason"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Belépés oka</label>
                  <span className="p-input-icon-right">
                    <Dropdown options={reasons}
                              optionLabel="name"
                              optionValue="id"
                              id={"entryReason"}
                              emptyMessage={"Még nincs bejegyzés..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  entryReason: e.value
                                })
                              }}
                              value={formData.entryReason}
                              showClear
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-id-card"}></i> Azonosító
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="identityDocumentType" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="identityDocumentType"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Dokumentum fajtája</label>
                  <span className="p-input-icon-right">
                    <Dropdown options={identitydocumenttypes}
                              optionLabel="name"
                              optionValue="id"
                              id={"identityDocumentType"}
                              emptyMessage={"Még nincs dokumentum..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  identityDocumentType: e.value
                                })
                              }}
                              value={Number(formData.identityDocumentType)}
                              showClear
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="identityDocumentNumber" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="identityDocumentNumber"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Okmány száma</label>
                  <span className="p-input-icon-right">
                      <InputText id="identityDocumentNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     identityDocumentNumber: e.target.value
                                   })
                                 }}
                                 value={formData.identityDocumentNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-sitemap"}></i> Vendéglátó
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                  <span className="p-input-icon-right">
                    {localStorage.getItem('roles').toString().includes('role_company_browse')
                    || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                      <AutoComplete value={selectedCompany}
                                    id={"company"}
                                    suggestions={companies}
                        // forceSelection
                                    completeMethod={searchCompany}
                                    field={"name"}
                                    delay="500"
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        company: {id: e.value.id}
                                      })
                                      setSelectedCompany(e.value.name)
                                      localStorage.setItem('defaultCompanyId', e.value.id)
                                      localStorage.setItem('defaultCompanyName', e.value.name)
                                    }}
                                    dropdown
                                    onClear={(e) => {
                                      setSelectedCompany('')
                                    }}
                      />
                    ) : (
                      <Dropdown disabled className={"disabled"} id={"company"} showClear/>
                    )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-md-6 p-lg-6"}>*/}
            {/*  <Field name="entryLocation" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="entryLocation"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt</label>*/}
            {/*      <span className="p-input-icon-right">*/}
            {/*        <AutoComplete value={selectedEntryLocation}*/}
            {/*                      id={"entryLocation"}*/}
            {/*                      suggestions={entryLocations}*/}
            {/*                      // forceSelection*/}
            {/*                      completeMethod={searchEntryLocation}*/}
            {/*                      field={"name"}*/}
            {/*                      delay="500"*/}
            {/*                      placeholder={"Keresés gépeléssel..."}*/}
            {/*                      className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*                      onSelect={(e) => {*/}
            {/*                        setFormData({*/}
            {/*                          ...formData,*/}
            {/*                          entryLocation: {id: e.value.id}*/}
            {/*                        })*/}
            {/*                        setSelectedEntryLocation(e.value.name)*/}
            {/*                        localStorage.setItem("defaultProjectId", e.value.id)*/}
            {/*                        localStorage.setItem("defaultProjectName", e.value.name)*/}
            {/*                      }}*/}
            {/*                      dropdown*/}
            {/*                      onClear={(e) => {*/}
            {/*                        setSelectedEntryLocation('')*/}
            {/*                      }}*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {formData.id &&
              <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                      onClick={() => {
                        window.App.setState({
                          props: formData,
                          sidebarGuestUpdate: false,
                          sidebarGuestCreate: false,
                          sidebarGuestShow: true,
                        })
                      }}
                      className="p-button"/>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="button" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        if(formData.id && formData.status === 'not_administrated') {
                          if (window.confirm('Biztosan minden adatot helyesen rögzített? Mentés után ' +
                            'módosításra már nem lesz lehetőség!')) {
                            props = {...props, closeForm: true};
                            onSubmit(formData);
                          }
                        } else {
                          props = {...props, closeForm: true};
                          onSubmit(formData);
                        }
                      }}
                      className="p-button-success"/>
              <Button type="button" label="Mentés" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        if(formData.id && formData.status === 'not_administrated') {
                          if (window.confirm('Biztosan minden adatot helyesen rögzített? Mentés után ' +
                            'módosításra már nem lesz lehetőség!')) {
                            props = {...props, closeForm: false};
                            onSubmit(formData)
                          }
                        } else {
                          props = {...props, closeForm: false};
                          onSubmit(formData)
                        }
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
        {localStorage.getItem('roles').toString().includes('role_identifier_read') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Azonosítók
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <div className={"p-grid"}>
            {(formData && formData.id) || (props.initialValues && props.initialValues.id) ? (
              <>
                {identifiers && identifiers[0] ? (
                  [identifiers.map((identifier, index) => {
                    return (
                      <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '200px'}}>
                        <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id}
                             className={"w-100"}/>
                        {identifier.serial ? (
                          <h3>{identifier.serial}</h3>
                        ) : (
                          <h3>{identifier.identifier}</h3>
                        )}
                      </div>
                    )
                  })]
                ) : (
                  <div className={"p-col-12"}>
                    Még nincs hozzárendelve azonosító vagy nincs jogosultság az azonosító megtekintéséhez.
                  </div>
                )}
              </>
            ) : (
              <><i className={"pi pi-lock"}></i>&nbsp; Az azonosítók megtekintése csak mentés után lehetséges!</>
            )}
          </div>
        </Panel>
        }
      </form>
    )}/>
  )
}
export default withTranslation('common')(GuestFormComponent);
