import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Sidebar} from "primereact/sidebar";
import {TabView, TabPanel} from 'primereact/tabview';
import {Chart} from 'primereact/chart';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";

import MachineListComponent from "../../components/machine/MachineListComponent";
import MachineFormComponent from "../../components/machine/MachineFormComponent";
import ScheduleitemscalendarFormComponent
  from "../../components/scheduleitemscalendar/ScheduleitemscalendarListComponent";
import ScheduleitemscalendarCalendarComponent
  from "../../components/scheduleitemscalendar/ScheduleitemscalendarCalendarComponent";
import ProductionitemscalendarCalendarComponent
  from "../../components/productionitemscalendar/ProductionitemscalendarCalendarComponent";
import SchedulegroupListComponent from "../../components/schedulegroup/SchedulegroupListComponent";
import SchedulegroupFormComponent from "../../components/schedulegroup/SchedulegroupFormComponent";
import ScheduleFormComponent from "../../components/schedule/ScheduleFormComponent";
import SchedulegroupShowComponent from "../../components/schedulegroup/SchedulegroupShowComponent";
import ScheduleShowComponent from "../../components/schedule/ScheduleShowComponent";
import ProductionitemShowComponent from "../../components/productionitemscalendar/ProductionitemscalendarShowComponent";
import ProductionitemscalendarFormComponent
  from "../../components/productionitemscalendar/ProductionitemscalendarFormComponent";
import ManufacturingSummaryDailyScheduleService
  from "../../services/manufacturing/ManufacturingSummaryDailyScheduleService";
import ManufacturingSummaryDailyTimeBreakdownByTechnologyService
  from "../../services/manufacturing/ManufacturingSummaryDailyTimeBreakdownByTechnologyService";
import ManufacturingSummaryScheduleCoverageService
  from "../../services/manufacturing/ManufacturingSummaryScheduleCoverageService";
import ManufacturingSummaryCapacityForecastService
  from "../../services/manufacturing/ManufacturingSummaryCapacityForecastService";
import ManufacturingSummaryDailyTimeBreakdownByCategoryService
  from "../../services/manufacturing/ManufacturingSummaryDailyTimeBreakdownByCategoryService";

export const ManufacturingDashboardPage = () => {

  const [dailySchedule, setDailySchedule] = useState([]);
  const [scheduleCoverage, setScheduleCoverage] = useState([]);
  const [capacityForecast, setCapacityForecast] = useState([]);
  const [dailyTimeBreakdownByTechnologyLables, setDailyTimeBreakdownByTechnologyLabels] = useState([]);
  const [dailyTimeBreakdownByTechnologyData, setDailyTimeBreakdownByTechnologyData] = useState([{}]);
  const [dailyTimeBreakdownByCategoryLables, setDailyTimeBreakdownByCategoryLabels] = useState([]);
  const [dailyTimeBreakdownByCategoryData, setDailyTimeBreakdownByCategoryData] = useState([{}]);

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Gyártás' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('manufacturing')[0]) {
        document.getElementsByClassName('manufacturing')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
    ManufacturingSummaryDailyScheduleService()
      .then(response => {
        setDailySchedule(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ManufacturingSummaryScheduleCoverageService()
      .then(response => {
        setScheduleCoverage(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ManufacturingSummaryCapacityForecastService()
      .then(response => {
        setCapacityForecast(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ManufacturingSummaryDailyTimeBreakdownByTechnologyService()
      .then(response => {
        setDailyTimeBreakdownByTechnologyLabels(response.data.labels)
        var palettes = [
          process.env.REACT_APP_COLOR1,
          process.env.REACT_APP_COLOR2,
          process.env.REACT_APP_COLOR3,
          process.env.REACT_APP_COLOR4,
          process.env.REACT_APP_COLOR5,
        ];
        let colorIndexStarter = -1
        let data = []
        response.data.datasets.map(function (dataset, index) {
          const colorIndex = ++colorIndexStarter % 5
          data.push({
            type: 'bar',
            label: dataset.name,
            data: dataset.data,
            backgroundColor: palettes[colorIndex],
            borderColor: palettes[colorIndex],
          })
        })
        setDailyTimeBreakdownByTechnologyData(data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ManufacturingSummaryDailyTimeBreakdownByCategoryService()
      .then(response => {
        setDailyTimeBreakdownByCategoryLabels(response.data.labels)
        var palettes = [
          process.env.REACT_APP_COLOR1,
          process.env.REACT_APP_COLOR2,
          process.env.REACT_APP_COLOR3,
          process.env.REACT_APP_COLOR4,
          process.env.REACT_APP_COLOR5,
        ];
        let colorIndexStarter = -1
        let data = []
        response.data.datasets.map(function (dataset, index) {
          const colorIndex = ++colorIndexStarter % 5
          let type = 'line'
          if(dataset.type === 'stacked') {
            type = 'bar'
          }
          let yAxisId = 'y'
          if(type === 'line') {
            yAxisId = 'y1'
          }
          data.push({
            type: type,
            label: dataset.name,
            data: dataset.data,
            backgroundColor: palettes[colorIndex],
            borderColor: palettes[colorIndex],
            yAxisID: yAxisId,

          })
        })
        console.log(data)
        setDailyTimeBreakdownByCategoryData(data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_manufacturing') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                <TabPanel header="Összefoglaló">
                  <div className={"p-p-5"}>

                    <h3 className={"p-mt-0"}>Napi műszak adatok</h3>
                    <DataTable
                      emptyMessage="Nincs találat."
                      value={dailySchedule}
                      emptyMessage="Nincs találat."
                      autoLayout={true}
                      responsiveLayout="scroll"
                      stripedRows
                    >
                      <Column field="scheduleGroupName" header="Műszak adatok"></Column>
                      <Column field="" header="Időtartam" body={(rowData) => {
                        return (
                          <>
                            {moment.utc(rowData.scheduledFrom).local().format('YYYY-MM-DD HH:mm')} - {moment.utc(rowData.scheduledUntil).local().format('YYYY-MM-DD HH:mm')}
                          </>
                        )
                      }}></Column>
                      <Column field="scheduleCapacity" header="Műszak kapacitás" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.scheduleCapacity} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="estimatedTime" header="Becsült munkaidő" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.estimatedTime} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="mainTime" header="Főidő" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.mainTime} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="auxiliaryTime" header="Mellékidő" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.auxiliaryTime} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="supplementaryTime" header="Pótidő" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.supplementaryTime} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                    </DataTable>
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-lg-6"}>
                        <h3 className={"p-mt-5"}>Napi ledolgozott órák technológia szerint</h3>
                        {dailyTimeBreakdownByTechnologyData && dailyTimeBreakdownByTechnologyData[0] &&
                        <Chart type="bar"
                               className={"p-mt-0"}
                               options={{
                                 animation: {
                                   duration: 0
                                 },
                                 plugins: {
                                   legend: {
                                     labels: {
                                       color: '#000'
                                     }
                                   }
                                 },
                                 scales: {
                                   x: {
                                     stacked: true,
                                     ticks: {
                                       color: '#000'
                                     },
                                     grid: {
                                       color: '#f3f4f5'
                                     }
                                   },
                                   y: {
                                     stacked: true,
                                     ticks: {
                                       color: '#000'
                                     },
                                     grid: {
                                       color: '#f3f4f5'
                                     }
                                   },
                                 }
                               }}
                               data={{
                                 labels: dailyTimeBreakdownByTechnologyLables,
                                 datasets: dailyTimeBreakdownByTechnologyData
                               }}/>
                        }
                      </div>
                      <div className={"p-col-12 p-lg-6"}>
                        <h3 className={"p-mt-5"}>Napi ledolgozott órák időkihasználtság szerint</h3>
                        {dailyTimeBreakdownByCategoryData && dailyTimeBreakdownByCategoryData[0] &&
                        <Chart type="bar"
                               className={"p-mt-0"}
                               options={{
                                 animation: {
                                   duration: 0
                                 },
                                 plugins: {
                                   legend: {
                                     labels: {
                                       color: '#000'
                                     }
                                   }
                                 },
                                 scales: {
                                   x: {
                                     stacked: true,
                                     ticks: {
                                       color: '#000'
                                     },
                                     grid: {
                                       color: '#f3f4f5'
                                     }
                                   },
                                   y: {
                                     stacked: true,
                                     ticks: {
                                       color: '#000'
                                     },
                                     grid: {
                                       color: '#f3f4f5'
                                     }
                                   },
                                   y1: {
                                     type: 'linear',
                                     display: true,
                                     position: 'right',
                                     ticks: {
                                       color: '#000'
                                     },
                                     grid: {
                                       drawOnChartArea: true,
                                       color: '#f3f4f5'
                                     }
                                   }
                                 }
                               }}
                               data={{
                                 labels: dailyTimeBreakdownByCategoryLables,
                                 datasets: dailyTimeBreakdownByCategoryData
                               }}/>
                        }
                      </div>
                    </div>
                    <h3 className={"p-mt-5"}>Műszak lefedettség</h3>
                    <DataTable
                      emptyMessage="Nincs találat."
                      value={scheduleCoverage}
                      autoLayout={true}
                      responsiveLayout="scroll"
                      stripedRows
                    >
                      <Column field="technologyName" header="Technológia"></Column>
                      <Column field="scheduleCapacity" header="Műszak" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.scheduleCapacity} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="" header="Tervezett időszak" body={(rowData) => {
                        return (
                          <>
                            {moment.utc(rowData.scheduledFrom).local().format('YYYY-MM-DD')} - {moment.utc(rowData.scheduledUntil).local().format('YYYY-MM-DD')}
                          </>
                        )
                      }}></Column>
                      <Column field="projectCoverage" header="Szerződött projekt"  className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.projectCoverage} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="projectLastDeadline" header="Határidő"></Column>
                      <Column field="coverageRatio" header="Arány" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.coverageRatio} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="freeCapacity" header="Szabad kapacitás" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.freeCapacity} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                    </DataTable>
                    <h3 className={"p-mt-5"}>Kapacitás előrejelzés</h3>
                    <DataTable
                      emptyMessage="Nincs találat."
                      value={capacityForecast}
                      autoLayout={true}
                      responsiveLayout="scroll"
                      stripedRows
                    >
                      <Column field="technologyName" header="Technológia"></Column>
                      <Column field="averageCapacity" header="Átlag napi kapacitás" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.averageCapacity} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="" header="Előrejelzési időszak" body={(rowData) => {
                        return (
                          <>
                            {moment.utc(rowData.estimatedFrom).local().format('YYYY-MM-DD')} - {moment.utc(rowData.estimatedUntil).local().format('YYYY-MM-DD')}
                          </>
                        )
                      }}></Column>
                      <Column field="totalCapacity" header="Összes kapacitás" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.totalCapacity} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="capacityRatio" header="Arány" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.capacityRatio} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                      <Column field="freeCapacity" header="Várható kapacitás" className={"p-text-center"} body={(rowData) => {
                        return (
                          <>
                            {rowData.freeCapacity} {rowData.unitName}
                          </>
                        )
                      }}></Column>
                    </DataTable>
                  </div>
                </TabPanel>
                {localStorage.getItem('roles').toString().includes('role_machine_read') ? (
                  <TabPanel header="Gépek">
                    <div className={"p-p-5"}>
                      <MachineListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_manufacturing_calendar') ? (
                  <TabPanel header="Műszakbeosztás">
                    <div className={"p-p-5"}>
                      <ScheduleitemscalendarCalendarComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_manufacturing_calendar') ? (
                  <TabPanel header="Gyártás ütemezés">
                    <div className={"p-p-5"}>
                      <ProductionitemscalendarCalendarComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_schedule_read') ? (
                  <TabPanel header="Műszakok">
                    <div className={"p-p-5"}>
                      <SchedulegroupListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_machine_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarMachineCreate: true})}
                       className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új Gép
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_schedule_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarSchedulegroupCreate: true})}
                       className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új műszak csoport
                      </Card>
                    </a>
                  </div>
                  }
                </div>
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                  <span className={"p-text-light"}
                        dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarMachineCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarMachineCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új gép</h2>
            <MachineFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarMachineUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarMachineUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Gép módosítása</h2>
            <MachineFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>

          <Sidebar position="left" visible={window.App.state.sidebarScheduleditemscalendarUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarScheduleditemscalendarUpdate: false})
                   }}>
            <ScheduleitemscalendarFormComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProductionitemUpdate} closeOnEscape={true}
                   style={{width: '750px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarProductionitemUpdate: false,
                       rerenderProductionitemscalendarList: true,
                     })
                   }}>
            <h2 className={"text-primary"}>Feladat módosítása</h2>
            <ProductionitemscalendarFormComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProductionitemShow} closeOnEscape={true}
                   style={{width: '750px'}}
                   onHide={() => {
                     window.App.setState({sidebarProductionitemShow: false})
                   }}>
            <h2 className={"text-primary"}>Feladat megtekintése</h2>
            <ProductionitemShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

          <Sidebar position="left" visible={window.App.state.sidebarSchedulegroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarSchedulegroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új műszak</h2>
            <SchedulegroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSchedulegroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarSchedulegroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Műszak módosítása</h2>
            <SchedulegroupFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarScheduleCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarScheduleCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új műszak</h2>
            <ScheduleFormComponent initialValues={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSchedulegroupShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarSchedulegroupShow: false})
                   }}>
            <h2 className={"text-primary"}>Műszak megtekintése</h2>
            <SchedulegroupShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarScheduleShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarScheduleShow: false})
                   }}>
            <h2 className={"text-primary"}>Műszak megtekintése</h2>
            <ScheduleShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(ManufacturingDashboardPage);
