import axios from "axios";

export default function ScheduleitemsProductionItemsService(scheduleItemId, query) {
  if(query && query.toString().includes(',"multiSortMeta":[]')) {
    query = query.replace(',"multiSortMeta":[]', '')
  }
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/schedule_item/" + scheduleItemId + "/production_items?" + query,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
