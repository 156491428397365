import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import Kalend, {CalendarView} from 'kalend'
import 'kalend/dist/styles/index.css';
import {Panel} from "primereact/panel";
import hu from "../../translations/hu/kalend.json"
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";

import EmployeeListService from "../../services/employee/EmployeeListService";
import ProductionitemAllService from "../../services/productionitem/ProductionitemAllService";
import ProductionitemFormService from "../../services/productionitem/ProductionitemFormService";

const ProductionitemscalendarCalendarComponent = (props) => {

  const [events, setEvents] = useState([]);
  const [filterEmployees, setFilterEmployees] = useState();
  const [employees, setEmployees] = useState([]);
  const [filterType, setFilterType] = useState('isScheduled');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateUntil, setFilterDateUntil] = useState('');

  useEffect(() => {
    if (filterEmployees !== undefined) {
      let filter = {}
      if (filterType === 'isScheduled') {
        filter = JSON.stringify({
          filters: {
            scheduledFrom: {value: filterDateFrom},
            scheduledUntil: {value: filterDateUntil},
            employeeIds: {value: "" + filterEmployees},
          }
        })
      }
      if (filterType === 'isProduced') {
        filter = JSON.stringify({
          filters: {
            producedFrom: {value: filterDateFrom},
            producedUntil: {value: filterDateUntil},
            employeeIds: {value: "" + filterEmployees},
          }
        })
      }
      ProductionitemAllService(filter)
        .then(response => {
          window.App.setState({
            rerenderProductionitemscalendarList: false
          })
          let _events = []
          response.data.map(function (action, index) {
            if (action && action.scheduledFrom) {
              _events.push({
                id: action.id,
                startAt: action.scheduledFrom,
                endAt: action.scheduledUntil,
                summary: action.employeeName + " / " + action.technologyName + " Terv: " + action.estimatedTime + " óra",
                status: action.status,
                // color: 'blue',
                // calendarID: 'work'
              })
            }
          })
          setEvents(_events)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [window.App.state.rerenderProductionitemscalendarList === true, filterEmployees, filterType, filterDateFrom, filterDateUntil])

  useEffect(() => {
    EmployeeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setEmployees(response.data.items)
        let filter = []
        response.data.items.map(function (item, index) {
          filter.push(item.id)
        })
        setFilterEmployees(filter)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Panel className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label>Munkavállaló</label>
              <span className="p-input-icon-right">
                <MultiSelect options={employees}
                             optionLabel="name"
                             optionValue="id"
                             emptyMessage={"Még nincs munkavállaló..."}
                             filter
                             onChange={(e) => {
                               setFilterEmployees(e.value)
                             }}
                             value={filterEmployees}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label>Típus</label>
              <span className="p-input-icon-right">
                <Dropdown
                  showClear
                  options={
                    [
                      {label: 'Terv', value: 'isScheduled'},
                      {label: 'Tény', value: 'isProduced'},
                    ]
                  }
                  onChange={(e) => {
                    setFilterType(e.value)
                  }}
                  value={filterType}
                />
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div style={{height: '665px'}}>
        <Kalend
          customLanguage={hu}
          onEventClick={(e) => {
            if (e.status === 'new' || e.status === 'scheduled' || e.status === 'in_progress') {
              window.App.setState({
                sidebarProductionitemUpdate: true,
                props: {id: e.id}
              })
            } else {
              window.App.setState({
                sidebarProductionitemShow: true,
                props: {id: e.id}
              })
            }
          }}
          onPageChange={(e) => {
            setFilterDateFrom(moment.utc(e.rangeFrom).local().format('YYYY-MM-DD HH:mm'))
            setFilterDateUntil(moment.utc(e.rangeTo).local().format('YYYY-MM-DD HH:mm'))
          }}
          onEventDragFinish={(e, c) => {
            let _data = {
              scheduledFrom: moment.utc(c.startAt).local().format('YYYY-MM-DD HH:00'),
              scheduledUntil: moment.utc(c.endAt).local().format('YYYY-MM-DD HH:00'),
            }
            ProductionitemFormService(_data, 'put', e.id)
              .then(response => {
                window.App.toastShow('success', 'Sikeres módosítás!');
                setTimeout(() => {
                  window.App.setState({
                    rerenderProductionitemscalendarList: true
                  })
                },500)
              })
              .catch(error => {

              })
          }}
          events={events}
          initialDate={new Date().toISOString()}
          hourHeight={20}
          initialView={CalendarView.DAY}
          disabledViews={[CalendarView.THREE_DAYS, CalendarView.WEEK, CalendarView.MONTH, CalendarView.AGENDA]}
          timeFormat={'24'}
          weekDayStart={'Monday'}
          language={'en'}
        />
      </div>
    </>
  )
}
export default withTranslation('common')(ProductionitemscalendarCalendarComponent);
