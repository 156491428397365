import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import moment from "moment";
import {Button} from "primereact/button";

import ProductionitemShowService from "../../services/productionitem/ProductionitemShowService";
import ProductionitemStatusesService from "../../services/productionitem/ProductionitemStatusesService";

const ProductionitemscalendarShowComponent = (props) => {

  const [formData, setFormData] = useState({});

  const [statuses, setStatuses] = useState([]);
  const status = (statusId) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === statusId)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    ProductionitemStatusesService()
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {

      })
    if (window.App.state.props) {
      ProductionitemShowService(window.App.state.props.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [window.App.state.rerenderProductionitemShow === true])

  return (
    <>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Alapadatok
            </h3>
          </div>
        </div>
      } className={""}>
        <div className={"p-grid"}>
          {formData.name &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Név</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.name}
            </p>
          </div>
          }
          {formData.status && statuses && statuses[0] &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Státusz</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {status(formData.status)}
            </p>
          </div>
          }
          {formData.productionItemNumber &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Munkaszám</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.productionItemNumber}
            </p>
          </div>
          }
          {formData.externalId &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Külső azonosító</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.externalId}
            </p>
          </div>
          }
          {formData.projectName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Projekt</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.projectName}
            </p>
          </div>
          }
          {formData.projectItemName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Projekt tétel</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.projectItemName}
            </p>
          </div>
          }
          {formData.technologyName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Technológia</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.technologyName}
            </p>
          </div>
          }
          {formData.machineName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Gép</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.machineName}
            </p>
          </div>
          }
          {formData.employeeName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Munkavállaló</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.employeeName}
            </p>
          </div>
          }
        </div>
      </Panel>

      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Ütemezés
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <div className={"p-grid"}>
          {formData.scheduledFrom &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Tervezett kezdés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {moment.utc(formData.scheduledFrom).local().format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
          }
          {formData.scheduledUntil &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Tervezett befejezés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {moment.utc(formData.scheduledUntil).local().format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
          }
          {formData.producedFrom &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Tényleges kezdés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {moment.utc(formData.producedFrom).local().format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
          }
          {formData.producedUntil &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Tényleges befejezés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {moment.utc(formData.producedUntil).local().format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
          }
          {formData.estimatedTime &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Becsült időráfordítás</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.estimatedTime} óra
            </p>
          </div>
          }
          {formData.productionTime &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Tényleges időráfordítás</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.productionTime} óra
            </p>
          </div>
          }
        </div>
      </Panel>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Módosít" icon={"pi pi-pencil"}
                  onClick={() => {
                    window.App.setState({
                      sidebarProductionitemShow: false,
                      sidebarProductionitemUpdate: true,
                      props: formData,
                    })
                  }}
                  className="p-button p-button-warning"/>
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(ProductionitemscalendarShowComponent);
